<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" style="max-height: 90px;" alt="logo"></a>
                    <p style="margin-top:-10px">
                        UPGRADE non è solo una società di formazione e consulenza. Il suo team è sì composto da professionisti e consulenti esperti nei settori della formazione
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/UpgradeGroupItalia/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/UpgradeGroupItalia/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>LINK</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>LOGIN</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Menù</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>DOVE SIAMO</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Salvador Allende 43/L<br>84081 Baronissi (Sa)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390892146927">Telefono: (+39) 089-2146927</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@upgradeformazione.it">info@upgradeformazione.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+393762143890">Lunedì-Venerdì<br>09:00 ÷ 18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Upgrade Formazione - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2 d-none">
                    <ul>
                        <li><a href="https://www.chianeseepartners.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
